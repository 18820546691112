
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.link-underlined {
  color: white;
  text-decoration: none;
  position: relative;
}

.link-underlined::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.link-underlined:hover::after {
  visibility: visible;
  width: 100%;
}

.link-underlined-french {
  color: white;
  text-decoration: none;
  position: relative;
  border-bottom: 2px solid white;
}

.link-underlined-french::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, blue, white, red);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.link-underlined-french:hover::after {
  visibility: visible;
  width: 100%;
}

.link-underlined-uk {
  color: white;
  text-decoration: none;
  position: relative;
  border-bottom: 2px solid white;
}

.link-underlined-uk::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, red, white, blue);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.link-underlined-uk:hover::after {
  visibility: visible;
  width: 100%;
}